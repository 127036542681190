import { createContext } from 'react';
import { IOrganization } from '~/lib/organizations/types/organization';

export const OrganizationContext = createContext<{
  organization: Maybe<WithId<IOrganization>>;
  setOrganization: (user: WithId<IOrganization>) => void;
}>({
  organization: undefined,
  setOrganization: (_) => _,
});
