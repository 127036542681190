import { LayoutStyle } from '~/core/layout-style';
import { GoogleAuthProvider } from 'firebase/auth';
import { StripeCheckoutDisplayMode } from '~/lib/stripe/types';

enum Themes {
  Light = 'light',
  Dark = 'dark',
}

const configuration = {
  site: {
    name: 'Flows',
    description:
      'Unite AI, Human Insight, and Code — Harness the power of Flows to tackle complex operational challenges. Optimize your processes with a platform that seamlessly integrates AI, human expertise, and automation.',
    themeColor: '#ffffff',
    themeColorDark: '#0a0a0a',
    siteUrl: process.env.NEXT_PUBLIC_SITE_URL as string,
    siteName: 'Flows',
    twitterHandle: 'synergy_shock',
    githubHandle: '',
    convertKitFormId: '',
    locale: process.env.DEFAULT_LOCALE,
  },
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    pipelineStorageBucket: process.env.NEXT_PUBLIC_CLOUD_STORAGE_PIPELINE_PACKETS_BUCKET,
  },
  auth: {
    // Enable MFA. You must upgrade to GCP Identity Platform to use it.
    // see: https://cloud.google.com/identity-platform/docs/product-comparison
    enableMultiFactorAuth: false,
    // When enabled, users will be required to verify their email address
    // before being able to access the app
    requireEmailVerification: process.env.NEXT_PUBLIC_REQUIRE_EMAIL_VERIFICATION === 'true',
    // NB: Enable the providers below in the Firebase Console
    // in your production project
    providers: {
      emailPassword: true,
      phoneNumber: false,
      emailLink: false,
      oAuth: [GoogleAuthProvider],
    },
    // Use Redirect or Popup strategy for oAuth.
    // By default, we use the redirect strategy.
    useRedirectStrategy: true,
  },
  environment: process.env.NODE_ENV ?? 'development',
  emulatorHost: process.env.NEXT_PUBLIC_EMULATOR_HOST,
  emulator: process.env.NEXT_PUBLIC_EMULATOR === 'true',
  production: process.env.NODE_ENV === 'production',
  theme: Themes.Light,
  features: {
    enableThemeSwitcher: true,
    enableAccountDeletion: getBoolean(process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_DELETION, false),
    enableOrganizationDeletion: getBoolean(
      process.env.NEXT_PUBLIC_ENABLE_ORGANIZATION_DELETION,
      false,
    ),
  },
  paths: {
    landing: '/',
    unauthorized: '/unauthorized',
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up',
    emailLinkSignIn: '/auth/link',
    onboarding: `/onboarding`,
    appHome: '/dashboard',
    directory: '/directory',
    communities: '/communities',
    feed: '/feed',
    ideasCorner: '/ideas-corner',
    chatbot: '/chatbot',
    calendar: '/calendar',
    pipelines: '/pipelines',
    queues: '/workers/queues',
    files: '/files',
    workers: {
      queues: '/workers/queues',
    },
    settings: {
      profile: '/settings/profile',
      organization: '/settings/organization',
      subscription: '/settings/subscription',
      authentication: '/settings/profile/authentication',
      email: '/settings/profile/email',
      password: '/settings/profile/password',
      integrations: '/settings/integrations',
      entities: '/settings/entities',
    },
    api: {
      checkout: `/api/stripe/checkout`,
      billingPortal: `/api/stripe/portal`,
    },
    createOrganization: `/onboarding/create-organization`,
  },
  navigation: {
    style: LayoutStyle.Sidebar,
  },
  appCheckSiteKey: process.env.NEXT_PUBLIC_APPCHECK_KEY,
  sentry: {
    dsn: process.env.SENTRY_DSN,
  },
  errors: {
    googleApplicationCredentials: process.env.GOOGLE_APPLICATION_CREDENTIALS,
  },
  stripe: {
    embedded: true,
    displayMode: StripeCheckoutDisplayMode.Popup,
    products: [
      {
        name: 'Basic',
        description:
          'Perfect for small teams! Ideal plan for getting started. As you grow, you can easily upgrade to a premium plan. Try it now!',
        badge: `Up to 10 users`,
        includes: 'Includes:',
        features: [
          'Up to 10 users',
          'Basic Reporting',
          'Up to 5 communities',
          'Full App Access',
          '1GB of storage per user',
        ],
        plans: [
          {
            name: '',
            price: 'Free',
            stripePriceId: 'price_1NNwYHI1i3VnbZTqI2UzaHIe',
          },
          // {
          //   name: 'Yearly',
          //   price: 'Free',
          //   stripePriceId: '',
          // },
        ],
      },
      {
        name: 'Premium',
        badge: `Most Popular`,
        recommended: true,
        description:
          'Experience unlimited potential! Perfect for growing teams needing comprehensive, scalable solutions to stay organized and connected.',
        includes: 'Everything in Free, plus:',
        features: [
          '10 users and up',
          'Advanced Reporting',
          'Unlimited Communities',
          'Organizational Visualization',
          '5GB of storage per user',
        ],
        plans: [
          {
            name: 'Per user/ Monthly',
            price: '$6',
            stripePriceId: 'price_1NNwYHI1i3VnbZTqI2UzaHIe',
          },
          // {
          //   name: 'Yearly',
          //   price: '$200',
          //   stripePriceId: '',
          // },
        ],
      },
      // {
      //   name: 'Premium',
      //   description:
      //     'Unlimited user access. Everything you need to manage your organization efficiently and keep your team engaged.',
      //   badge: ``,
      //   features: [
      //     'Unlimited Communities and Users',
      //     'Premium Reporting and Analytics',
      //     '50GB per User',
      //   ],
      //   plans: [
      //     {
      //       name: '',
      //       price: 'Contact us',
      //       stripePriceId: '',
      //       label: `Contact us`,
      //       href: `/contact`,
      //     },
      //   ],
      // },
    ],
  },
};

export default configuration;

// Validate Stripe configuration
// as this is a new requirement, we throw an error if the key is not defined
// in the environment
if (
  configuration.stripe.embedded &&
  configuration.production &&
  !process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
) {
  throw new Error(
    'NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY is not defined. Please' +
    ' add it to your environment variables.',
  );
}

function getBoolean(value: unknown, defaultValue: boolean) {
  if (typeof value === 'string') {
    return value === 'true';
  }

  return defaultValue;
}
